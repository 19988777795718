@keyframes swipe {
  from {
    width: 1%;
  }

  to {
    width: 100%;
  }
}

.logoHolder {
  width: 1%;
  animation: swipe 2s forwards;
  overflow: hidden;
}

.logoHolder img.logo {
  width: 216px;
}


/* md */
@media only screen and (min-width: 576px) {
  .logoHolder img.logo {
    width: 432px;
  }
}

/* lg */
@media only screen and (min-width: 768px) {}

/* xl: 960px */