@keyframes slide-in {
  0% {
    filter: opacity(0.0);
    max-height: 0;
  }

  50% {
    max-height: 1000px;
  }

  100% {
    filter: opacity(1);
  }
}

.slide {
  animation: slide-in 1.2s forwards;
  min-width: 100%;
  height: auto;
  margin: 12px 0;
}

.slide.prompt {
  margin: 12px 0 0 0;
}


/* md */
@media only screen and (min-width: 576px) {
  .slide {
    margin: 20px 0 20px 0;
  }

  .slide.prompt {
    margin: 20px 0 0 0;
  }

}