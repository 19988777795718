.typeable {
  line-height: 24px;
}

.text {
  font-size: 14px;
  font-weight: normal;
  color: #FFF;
}

h1.titleLarge {
  font-size: 38px;
  padding: 12px;
  border-bottom: 1px solid #fff3;
}

h2.title {
  font-size: 24px;
  margin: 12px 0;
}

/* md */
@media only screen and (min-width: 576px) {
  .typeable {
    line-height: 32px;
  }

  .text {
    font-size: 18px;
  }
}