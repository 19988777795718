.circle {
  background: #dbdbdbcc;
  width: 240px;
  height: 240px;
  border-radius: 120px;
  position: relative;
}

/* TODO: smaller version on mobile, maybe just scale it down? */

.dotsHolder {
  width: 120px;
  height: 120px;
  top: 0;
  left: 0;
  position: absolute;
}

.dot {
  background: #1c1c1c;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  position: absolute;
  top: 100%;
  left: 100%;
  margin: -12px;
}

.midnight {
  background: #1c1c1c;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 32px;
  height: 84px;
  margin: -70px -16px;
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
}

.minuteHand {
  background: #a71111;
  position: absolute;
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  left: 50%;
  top: 50%;
  width: 18px;
  height: 112px;
  margin: -94px -9px;
  transform-origin: center 94px;
  transition: transform 0.3s;
}