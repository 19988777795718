.playerList {
  width: 100%;
  margin: 8px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

.playerList.allReady {
  filter: opacity(0.6);
}

/* md */
@media only screen and (min-width: 576px) {
  .playerList {
    width: calc(100% - 40px);
    margin: 20px;
  }
}