.pill {
  padding: 3px 8px;
  border-radius: 2px;
  font-size: 12px;
  color: #1c1c1c;
  background: #dbdbdb;
  margin: 4px;
  font-weight: bold;
  font-family: monospace;
  transition: 0.3s;
}

.pill.red {
  color: #dbdbdb !important;
  background: #a71111;
}

.pill svg {
  margin: 0px 8px 0px 0px;
  height: 14px;
  display: none;
}

/* share buttons */

.pill.facebook {
  color: #dbdbdb;
  background: #4065ad;
}

.pill.twitter {
  color: #dbdbdb;
  background: #1ea2f1;
}

/* links */

.pill.paypal {
  color: #dbdbdb !important;
  background: #013088;
}

a.linkPill {
  text-decoration: none;
}

a.linkPill .pill {
  color: #165ac3;
}

a:hover .pill {
  color: #073a86;
}

.pill.actionable {
  cursor: pointer;
}

.pill.actionable:hover {
  background: #b5b5b5;
}

.responded {
  filter: opacity(0.6);
}


/* md */
@media only screen and (min-width: 576px) {
  .pill {
    font-size: 18px;
    white-space: pre;
  }

  .pill svg {
    display: inline;
  }
}