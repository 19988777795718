.gameContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.gameContainer .slidesHolder {
  width: 80%;
  left: 10%;
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}