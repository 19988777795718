.playerListHolder {
  background: #0008;
  border-radius: 2px;
  padding: 2px;
  margin-bottom: 8px;
  cursor: pointer;
}

.roomRow {
  display: flex;
  flex-wrap: wrap;
  min-height: 127px;
  width: calc(100% + 8px);
  margin-left: -4px;
}

.roomHolder {
  padding: 4px;
}

.roomHolder.r3 {
  width: 33.333333%;
}

.roomHolder.r4 {
  width: 25%;
}

.roomHolder.r5 {
  width: 33.333333%;
}

.roomHolder.r6 {
  width: 33.333333%;
}

.room {
  height: 100%;
  width: 100%;
  background: #0008;
  cursor: pointer;
  border-radius: 2px;
  overflow: hidden;
}

.room.disabled {
  cursor: default;
}

.roomNumber {
  width: 100%;
  font-size: 26px;
  font-weight: bold;
  font-family: monospace;
  text-align: center;
  padding: 8px;
}

.players {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

/* md */
@media only screen and (min-width: 576px) {
  .roomRow {
    min-height: 169px;
    width: calc(100% + 16px);
    margin-left: -8px;
  }

  .roomHolder {
    padding: 8px;
    width: 20% !important;
  }

  .roomHolder.r5 {
    width: 20%;
  }

  .roomHolder.r6 {
    width: 16.6666667%;
  }
}