.button {
  width: 100%;
  height: 100%;
  padding: 4px;
  margin: 0 auto;
  text-align: center;
  background: #FFF1;
  border: none;
  color: #fff;
  font-family: inherit;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}

.button:hover,
.button:focus {
  background: #FFF3;
}

.button:active {
  background: #FFF2;
}

.button:disabled {
  pointer-events: none;
  filter: opacity(0.6);
}


/* md */
@media only screen and (min-width: 576px) {
  .button {
    padding: 20px;
    font-size: 18px;
  }
}